@import 'Assets/styles/common.scss';

.CustomerDesgin {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.CJViewer {
  // background: rgb(215, 215, 215);
}

.CustomToolsComponent {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px;

  .edit-actions {
    font-size: 20px;
    .anticon {
      margin-right: 20px;
    }
  }

  .view-actions {
    display: flex;
    flex-direction: column;

    .tool {
      font-size: 40px !important;
      width: 40px !important;
      height: 40px !important;
      border-radius: 4px !important;
      background-color: white !important;
      box-shadow: 0px 4px 16px 0px #0000001f !important;
      @include CJ-circle-button;
      padding: 5px;
      margin: 5px 0;

      svg {
        color: var(--gray_9);
        transition: 0.2s;
        width: 20px;
      }
    }
  }
}
